<template>
  <section class="row mb-2">
    <div class="col-12">
      <b-form-checkbox v-model="inputValue" size="lg" :switch="flip" :disabled="disabled" :required="required" :state="isRequiredAndFilled"><p>{{text}}</p></b-form-checkbox>
    </div>
  </section>
</template>

<script>
import requiredFieldMixin from "../../../mixins/requiredFieldMixin";

export default {
  name: "InputCheck",
  props: ["value", "disabled", "required", "flip", "text"],
  mixins: [requiredFieldMixin],
  data() {
    return {};
  },
 computed: {
    inputValue: {
      get() {
        return this.value; //Returnerer prop val
      },
      set(val) {
        this.$emit("input", val); //Emitter ændring til parent hvor parent så laver mutation via vo
      },
    },
  },
};
</script>

<style>
</style>