<template>
    <select
      class="form-control"
      v-model="inputValue"
      :disabled="disabled"
    >
      <option
        v-for="(supplier, index) in suppliersSorted"
        :key="supplier.id"
        :value="supplier.id"
        :selected="supplier.id == value"
      >
        {{ supplier.name }}
      </option>
    </select>
</template>
<script>
export default {
  name: "Supplier",
  props: {
    suppliers: Array,
    value: String,
    disabled: Boolean,
  },
  computed: {
    suppliersSorted() {
      return this.suppliers.sort((a, b) => a.name.localeCompare(b.name));
    },
    inputValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>
<style lang="scss">
</style>